import Head from 'next/head';
import { fetchSeoContent, fetchKeywords } from 'api/seo';
import { GetStaticProps, GetStaticPaths } from 'next';
import { Footer } from 'components/sections/Footer';
import { Intro } from 'components/seo-pages/category/Intro';
import { BodySection, SeoPage, IntroSection } from 'api/seo/types';
import { BotsList } from 'components/seo-pages/category/BotsList';
import { TextBody } from 'components/seo-pages/category/TextBody';
import { ValuePropositions } from 'components/sections/ValuePropositions';
import { SubscriptionCTA } from 'components/sections/SubcriptionCTA';
import useWindowWidth from 'hooks/useWindowWidth';

const LandingPage = ({ page, landingMapping, categoryMapping }: Props) => {
  const introSection = page?.content.sections.find((section) => section.section === 'intro') as IntroSection;
  const bodySection = page?.content.sections.find((section) => section.section === 'text_body') as BodySection;

  const title = introSection?.title;
  const description = introSection?.content;

  const width = useWindowWidth();

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Head>

      {introSection && <Intro data={introSection} keyword={page?.keyword} windowWidth={width} />}
      <div className="max-w-[1280px] m-auto mt-8 px-4">
        <div className="flex flex-col md:gap-16 gap-8 mb-12 px-4">
          {page?.content.bot_list.length ? <BotsList bots={page?.content.bot_list} landingMapping={landingMapping} categoryMapping={categoryMapping} /> : null}
          {bodySection && <TextBody data={bodySection} landingMapping={landingMapping} categoryMapping={categoryMapping} />}
          <ValuePropositions />
        </div>
      </div>

      <Footer />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const keywords = await fetchKeywords('landing');
  const paths = keywords.map(({ keyword_slug }) => ({
    params: { landingPage: keyword_slug }
  }));

  return { paths, fallback: true };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  try {
    const path = (params?.landingPage as string) || '';
    const landingKeywords = await fetchKeywords('landing');
 
    const keywordExists = landingKeywords.some((landingKeywords) => landingKeywords.keyword_slug === path);   
    if (!keywordExists) {
      return { notFound: true };
    }
    
    const page = await fetchSeoContent({ keyword_slug: path, keyword_type: 'landing' });

    // The Mappings are [slug -> keyword]
    const categoryKeywords = await fetchKeywords('category');
    const landingMapping = Object.fromEntries(landingKeywords.map((keyword) => [keyword.keyword_slug, keyword.keyword]));
    const categoryMapping = Object.fromEntries(categoryKeywords.map((keyword) => [keyword.keyword_slug, keyword.keyword]));

    return { props: { page, landingMapping, categoryMapping } };
  } catch (err) {
    return { notFound: true };
  }
};

type Props = {
    page: SeoPage;
    landingMapping: Record<string, string>; // Changed to object type
    categoryMapping: Record<string, string>; // Changed to object type
};

export default LandingPage;
